/* FULLCALENDAR */
.fc-state-default {
  background-color: #ffffff;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: inherit;
  text-shadow: none;
}
.fc-state-default {
  border: 1px solid;
}
.fc-button {
  color: inherit;
  border: 1px solid @border-color;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap;
}
.fc-state-active {
  background-color: @color-navy-blue;
  border-color: @color-navy-blue;
  color: #ffffff;
}
.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit;
}
.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
  border-color: @border-color;
  font-weight: normal;
}
.fc-border-separate tbody {
  background-color: #F8F8F8;
}
.fc-state-highlight {
  background: none repeat scroll 0 0 @color-background;
}
.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px;
}
.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px;
}
.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: @color-navy-blue;
  border-color: @color-navy-blue;
}
.fc-event-time,
.fc-event-title {
  color: #717171;
  padding: 0 1px;
}
.ui-calendar .fc-event-time,
.ui-calendar .fc-event-title {
  color: #fff;
}

.fc-view-container .fc-widget-header {
  background: lighten(@color-background, 2%);
}

.fc-widget-header .fc-day-header {
  padding: 12px 0;
}

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: center;
}

.fc-basic-view td.fc-day-number, .fc-basic-view td.fc-week-number span {
  padding: 6px;
}

.fc-day-grid-event {
  margin: 2px 6px 0;
}