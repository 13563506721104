.angular-ui-tree-handle {
  background: none repeat scroll 0 0 @color-bright;
  border: 1px dashed @border-color;
  color: inherit;
  padding: 10px;
  font-weight: normal;
}
.angular-ui-tree-handle:hover {
  font-weight: bold;
  cursor: pointer;
}
.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed @border-color;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.group-title {
  background-color: #687074 !important;
  color: #FFF !important;
}
.tree-node {
  background: none repeat scroll 0 0 @color-bright;
  border: 1px dashed @border-color;
  color: inherit;
  padding: 10px;
  border-radius: 3px;
}

.tree-node .btn {
  min-width: 22px;
  margin-right: 4px;
}

.tree-node-content {
  margin: 5px 5px 5px 0;
}

.tree-handle {
  background: none repeat scroll 0 0 @color-bright;
  border: 1px dashed @border-color;
  color: inherit;
  padding: 10px;
}

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed @border-color;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}