.pe-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.pe-2x {
    font-size: 2em;
}
.pe-3x {
    font-size: 3em;
}
.pe-4x {
    font-size: 4em;
}
.pe-5x {
    font-size: 5em;
}
.pe-fw {
    text-align: center;
    width: 1.2857142857142858em;
}
.pe-ul {
    list-style-type: none;
    margin-left: 2.142857142857143em;
    padding-left: 0;
}
.pe-ul>li {
    position: relative;
}
.pe-li {
    left: -2.142857142857143em;
    position: absolute;
    text-align: center;
    top: 0.14285714285714285em;
    width: 2.142857142857143em;
}
.pe-li.pe-lg {
    left: -1.8571428571428572em;
}
.pe-border {
    border-radius: .1em;
    border: solid 0.08em #eaeaea;
    border: solid 0.08em #eeeeee;
    padding: .2em .25em .15em;
}
.pull-right {
    float: right;
}
.pull-left {
    float: left;
}
.pe.pull-left {
    margin-right: .3em;
}
.pe.pull-right {
    margin-left: .3em;
}
.pe-spin {
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
}
@-moz-keyframes
    spin {
        0% {
            -moz-transform: rotate(0deg);
        }
    }
    100% {
        -moz-transform: rotate(359deg);
        -ms-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }

@-webkit-keyframes
    spin {
        0% { -webkit-transform: rotate(0deg);
        }
    }
@-o-keyframes
    spin {
        0% { -o-transform: rotate(0deg);
        }
    }
@-ms-keyframes
    spin {
        0% { -ms-transform: rotate(0deg);
        }
    }
@keyframes
    spin {
        0% { transform: rotate(0deg);
        }
    }
    .pe-rotate-90 {
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
        transform: rotate(90deg);
    }
    .pe-rotate-180 {
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
        transform: rotate(180deg);
    }
    .pe-rotate-270 {
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -webkit-transform: rotate(270deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
        transform: rotate(270deg);
    }
    .pe-flip-horizontal {
        -moz-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
        transform: scale(-1, 1);
    }
    .pe-flip-vertical {
        -moz-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        -webkit-transform: scale(1, -1);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
        transform: scale(1, -1);
    }
    .pe-stack {
        display: inline-block;
        height: 2em;
        line-height: 2em;
        position: relative;
        vertical-align: middle;
        width: 2em;
    }
    .pe-stack-1x {
        left: 0;
        line-height: inherit;
        position: absolute;
        text-align: center;
        width: 100%;
    }
    .pe-stack-2x {
        font-size: 2em;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
    }
    .pe-inverse {
        color: #ffffff;
    }
    .pe-va {
        vertical-align: middle;
    }

[class^="pe-7s-"] {
    display: inline-block;
}
[class*=" {
	pe-7s-"] {
    display: inline-block;
}
